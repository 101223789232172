<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img
                        src="@/assets/images/app_logo.jpeg"
                        alt=""
                        height="40"
                    />
                  </span>
                </router-link>

              </div>

            </div>

            <form @submit.prevent="tryToLogIn" v-if="!verificationStage">
              <h4 class="text-muted mb-4 mt-3 text-center">
                SignIn / SignUp IwinInvest
              </h4>
              <h5 style="font-weight: 500" class="text-danger">**Instructions**</h5>
              <ul class="mb-4">
                <li>Enter your email</li>
                <li>You will receive verification code from your email</li>
              </ul>
              <!--              <b-alert-->
              <!--                  :variant="notification.type"-->
              <!--                  class="mt-3"-->
              <!--                  v-if="notification.message"-->
              <!--                  :show="notificationAutoCloseDuration"-->
              <!--                  dismissible-->
              <!--              >{{ notification.message }}-->
              <!--              </b-alert-->
              <!--              >-->

              <div class="form-group mb-3">
                <label for="emailaddress">Email address</label>
                <input
                    class="form-control"
                    v-model="email"
                    type="email"
                    required
                    id="emailaddress"
                    placeholder="Enter your email"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                >
                  <span v-if="!$v.email.required">Email is required.</span>
                  <span v-if="!$v.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-dark btn-block" type="submit">
                  Proceed
                </button>
              </div>
            </form>

            <form @submit.prevent="verificationLogin" v-else>
              <div class="alert alert-success mt-3" role="alert">
                <p>We just sent a code to <b>{{ email }}</b>
                  <a style="cursor: pointer; text-decoration: underline"
                     @click="verificationStage=false">(undo)</a>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="password">Verification code</label> <b class="text-danger"> {{ errorMessage }}</b>
                <div class="input-group input-group-merge">
                  <input
                      v-model="password"
                      type="text"
                      id="password"
                      required
                      class="form-control"
                      placeholder="verification code"
                      :class="{ 'is-invalid': error }"
                  />
                </div>
              </div>
              <div class="form-group mb-0 text-center">
                <button class="btn btn-dark btn-block" type="submit">
                  Verify code
                </button>
              </div>

            </form>

          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

<!--        <div class="row mt-3">-->
<!--          <div class="col-12 text-center">-->
<!--            <p>-->
<!--              <router-link to="/forgot-password" class="text-muted ml-1"-->
<!--              >Forgot your password?-->
<!--              </router-link-->
<!--              >-->
<!--            </p>-->

<!--          </div>-->
<!--          &lt;!&ndash; end col &ndash;&gt;-->
<!--        </div>-->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";

import {required, email} from "vuelidate/lib/validators";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      tryingToLogIn: false,
      obscurePassword: true,
      verificationStage: false,
      success: false,
      error: false,
      errorMessage: ''
    };
  },
  components: {
    Auth,
  },

  watch: {
    verificationStage: function (data) {
      if (!data) {
        this.password = '';
        this.error = false;
        this.errorMessage = '';
      }

    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.getters.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.getters.notification ? 5 : 0;
    },
  },
  created() {
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    verificationLogin() {
      this.error = false;
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      }).then((res) => {
        const {status, message} = res;
        console.log(status, message)
        if (status) {
          this.$router.push('/')
        } else {
          this.error = true;
          this.errorMessage = message
        }
      })
    },
    tryToLogIn() {
      this.$store.dispatch("login", {
        email: this.email,
        password: '',
      }).then((res) => {
        const {status} = res;
        if (status) {
          this.verificationStage = true;
        }
      })
      // this.$store.dispatch("clear");
    },
  },
};
</script>
